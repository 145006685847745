import React,{useEffect} from 'react';
import AdminSidebar from '../../../components/AdminSidebar/sidebar';
import styles from './requests.module.css'
import bank_icon from '../../../assets/icons/bank.svg'
import check_icon from '../../../assets/icons/check-circle(green).svg'
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { connect } from 'react-redux';
import { clearRequestSuccess, declineRequest, flutterWavePayment, manualRequest } from '../../../store/actions/requests';
import { useNavigate, useParams } from 'react-router-dom';
import Moment from "react-moment";

const AdminRequestDetailsPage = ({requests, requestsuccess, clearRequest, handleManual, handleDecline, loader, role}) => {


 const {id} = useParams()

 const navigate = useNavigate()

 const request = requests.find((val) => val._id === id);

    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result =  styles.pending_badge
            break;
          case "Completed":
            result = styles.success_badge
            break;
          case "Declined":
            result =  styles.failed_badge
            break;
          default:
            break;
        }
        return result;
      };

    const Approve = () => {
      confirmAlert({
        title: "Confirm to submit",
        message: `How would you like to approve this transaction?`,
        buttons: [
          {
            label: "Manual Confirmation",
            onClick: () => ManualApprove(),
          },
          {
            label: "Flutterwave Approve",
            onClick: () => FlutterwavePay(),
          },
        ],
      });
    };

    const ManualApprove = () => {
      handleManual(id)
    };
  
    const FlutterwavePay = () => {
      // handleFlutterPay(id)
      alert('This feature is not available yet for use!')
    };
  
    
    const Decline = () => {
      confirmAlert({
        title: "Confirm to submit",
        message: `You are about to decline this request, Do you wish to continue?`,
        buttons: [
          {
            label: "Yes",
            onClick: () => confirmDecline(),
          },
          {
            label: "No",
          },
        ],
      });
    };
  
    const confirmDecline = () => {
      handleDecline(id)
    };


  useEffect(()=>{
    if(requestsuccess){
        setTimeout(()=>{
            clearRequest()
            navigate('/requests')
        },[2000])
    }
  },[requestsuccess, navigate, clearRequest])



    return (  
        <>
            <AdminSidebar>
            <p className="titlehead">Request Details</p>
            </AdminSidebar>
            <div className="usermain"> 
                 <div className="contain">

                     <div className={`mt-5 ${styles.tradeCard}`}>

                       <div className={styles.requestWrapper}>
                         <div className={styles.firstHalf}>

                            <h6 className={`mb-0 ${styles.cardTitle}`}>Account Information</h6>

                            <div className={`mt-4 mb-4 ${styles.accountDetailsDiv}`}>
                            <div className={`d-none d-lg-block ${styles.accountDetailsIcon}`}>
                                 <img src={check_icon} className='img-fluid' alt="check" />
                            </div>

                            <div className={`${styles.accountDiv}`}>
                                    <div className={styles.accountInfo}>
                                        <div className={styles.accountIcon}>
                                             <img src={bank_icon} alt="bank" className='img-fluid' />
                                        </div>
                                        <div>
                                            <h6 className={`mb-0 ${styles.accountNm}`}>{request ? request.accountName : 'N/A'}</h6>
                                            <p className={`mb-0 mt-1 ${styles.accountNo}`}>{request ? request.accountNumber : 'N/A'}</p>
                                            <p className={`mb-0 mt-1 ${styles.bankTitle}`}>{request ? request.bankName : 'N/A'}</p>
                                        </div>
                                    </div>    
                            </div>
                          </div>

                         </div>

                         <div className={styles.secondHalf}>
                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Request Id</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{request ? request._id : 'transa1344xx'}</p>
                                </div>

            
                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Narration</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{request ? request.narration : 'Narration'}</p>
                                </div>

                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Amount</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>NGN {request ? request.amount : '0.00'}</p>
                                </div>

                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Date Requested</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>
                                      <Moment format="MMMM Do, YYYY">
                                        {request.createdAt}
                                    </Moment>
                                  </p>
                                </div>

                                <div>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Status</h6>
                                    <div className='d-flex'>
                                      <p className={`mt-2 ${styles.info_subtitle} ${getStatusColor(request ? request.status : 'Pending')}`}>{request.status}</p>
                                     </div>
                                </div>
                         </div>
                      </div>

                      {
                        request.status === 'Pending' &&
                      <div className={`mt-5 ${styles.cta_btn}`}>
                                  <button
                                      type="submit"
                                      className={`btn mb-3 mb-lg-0 ${styles.btn_approve}`}
                                      onClick={() => {
                                        Approve();
                                        }}
                                        disabled={loader || role === "SubAdmin"}
                                    
                                  >
                                      Approve Request
                                  </button>
                                  <button
                                      type="submit"
                                      className={`btn ${styles.btn_decline}`}
                                      onClick={() => {
                                        Decline();
                                        }}
                                        disabled={loader || role === "SubAdmin"}
                                  >
                                      Decline Request
                                  </button>
                        
                       </div>
                      }

                    </div>



                  </div>
           </div>
        </>
    );
}

const mapStateToProps = (state) => {
  return {
      requests: state.request.requests,
      loader: state.request.loader,
      requestsuccess: state.request.requestsuccess,
      role: state.auth.role,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleDecline: (id) => dispatch(declineRequest(id)),
   handleManual: (id) => dispatch(manualRequest(id)),
   handleFlutterPay: (id) => dispatch(flutterWavePayment(id)),
   clearRequest: () => dispatch(clearRequestSuccess()),
  };
}
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminRequestDetailsPage);