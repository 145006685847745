import React, {useState, useEffect} from 'react';
import './sidebar.css'  
import {Link, useLocation} from 'react-router-dom'
import Logo from '../../assets/icons/CBLogo.svg'
import home_icon from '../../assets/icons/Dashboard.svg'
import trade_icon from '../../assets/icons/trades_icon.svg'
import wallet_icon from '../../assets/icons/wallets_icon.svg'
import account_icon from '../../assets/icons/accounts_icon.svg'
import setting_icon from '../../assets/icons/Setting.svg'
import profile_icon from '../../assets/icons/profile_icon.svg'
import bell_icon from '../../assets/icons/bell.svg'
import user_p from '../../assets/images/userProfile.svg'
import power_icon from '../../assets/icons/power.svg'
import withdraw_icon from '../../assets/icons/withdrawfunds_icon.svg'
import { connect } from 'react-redux';
import { logOut } from '../../store/actions/auth';


const AdminSidebar = (props) => {
    
    const {Logout,userRole} = props

    // check for which path you are on
    const isActive = useLocation().pathname;

    const [sideShow, setSideShow] = useState(false);

    useEffect(() => {
        document.body.classList.add("userBg");
      }, []);
    
    const ToggleSidebar = () => {
        setSideShow(sideShow ? false : true);
    };

    // const closeSideBar = useCallback(() => {
    //     setSideShow(sideShow ? false : true);
    //   }, [sideShow]);
    
    // useEffect(() => {
    //     if (sideShow) {
    //         document.body.addEventListener("click", closeSideBar);
    
    //         return () => document.body.removeEventListener("click", closeSideBar);
    //     }
     
    // }, [sideShow, closeSideBar]);

    const ToggleLogout = () =>{
        Logout();
    }

    
    return ( 
        <>
          <div className={sideShow ? "userside show" : "userside"}>

            {/* logo */}
             <div className="logo mt-4">
                <img src={Logo} alt="logo" />
             </div>

                {/* nav list */}
                <div className="v-list">

                    <div>
                            <Link
                                to="/overview"
                                    className={
                                        isActive.includes("/overview")
                                        ? "v-list-item v-list-link active"
                                        : "v-list-item v-list-link"
                                    }
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                        className="v-list-icon"
                                    >  
                                    <img src={home_icon} alt="overview" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                        Overview
                                        </span>
                                    </div>
                            </Link>

                            <Link
                                to="/trades"
                                    className={
                                        isActive.includes("/trade")
                                        ? "v-list-item v-list-link active"
                                        : "v-list-item v-list-link"
                                    }
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                        className="v-list-icon"
                                    >  
                                    <img src={trade_icon} alt="trades" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                        Trades
                                        </span>
                                    </div>
                            </Link>

                            <Link
                                to="/transactions"
                                    className={
                                        isActive.includes("/transaction")
                                        ? "v-list-item v-list-link active"
                                        : "v-list-item v-list-link"
                                    }
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                        className="v-list-icon"
                                    >  
                                    <img src={wallet_icon} alt="wallet" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                        Transactions
                                        </span>
                                    </div>
                            </Link>

                            <Link
                                to="/requests"
                                    className={
                                        isActive.includes("/request")
                                        ? "v-list-item v-list-link active"
                                        : "v-list-item v-list-link"
                                    }
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                        className="v-list-icon"
                                    >  
                                    <img src={withdraw_icon} alt="requests" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                        Withdrawal Requests
                                        </span>
                                    </div>
                            </Link>

                            <Link
                                to="/users"
                                    className={
                                        isActive.includes("/user")
                                        ? "v-list-item v-list-link active"
                                        : "v-list-item v-list-link"
                                    }
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                        className="v-list-icon"
                                    >  
                                    <img src={account_icon} alt="users" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                        Users
                                        </span>
                                    </div>
                            </Link>
                        
                            {
                                userRole === 'Admin' &&
                            <Link
                                to="/admin"
                                    className={
                                        isActive.includes("/admin")
                                        ? "v-list-item v-list-link active"
                                        : "v-list-item v-list-link"
                                    }
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                        className="v-list-icon"
                                    >  
                                    <img src={account_icon} alt="users" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                        Admin
                                        </span>
                                    </div>
                            </Link>
                            }

                            <Link
                                to="/rates"
                                    className={
                                        isActive.includes("/rate")
                                        ? "v-list-item v-list-link active"
                                        : "v-list-item v-list-link"
                                    }
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                        className="v-list-icon"
                                    >  
                                    <img src={account_icon} alt="rates" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                    Giftcard Rates
                                        </span>
                                    </div>
                            </Link>

                            {
                             userRole === 'Admin' &&
                            <Link
                                to="/settings"
                                    className={
                                        isActive.includes("/settings")
                                        ? "v-list-item v-list-link active"
                                        : "v-list-item v-list-link"
                                    }
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                        className="v-list-icon"
                                    >  
                                    <img src={setting_icon} alt="settings" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                    Settings
                                        </span>
                                    </div>
                            </Link>    
                            }
                            
                            <Link
                                to="/profile"
                                    className={
                                        isActive.includes("/profile")
                                        ? "v-list-item v-list-link active"
                                        : "v-list-item v-list-link"
                                    }
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                    className="v-list-icon"
                                    >  
                                    <img src={profile_icon} alt="profile" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                        Profile
                                        </span>
                                    </div>
                            </Link>

                        <Link to="/"  onClick={ToggleLogout} className='sidebar-footer-layout'>
                            <div className='sidebar-footer-img'>
                                <img src={power_icon} alt="logout icon" />  
                            </div>
                            <div className='sidebar-footer-text'>
                                <span>Logout</span>
                            </div>
                        </Link>
                    </div>

                  
                        
                </div>
          </div>

            {/* navbar */}
            <nav>
                <div className="sidehead">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {/* icon for hamburger */}
                        <div
                        onClick={ToggleSidebar}
                        className="d-md-none d-sm-block"
                        style={{ marginRight: "12px", cursor: "pointer" }}
                        >
                        <i className="mdi mdi-menu" style={{ fontSize: 24, color:"#F47D20" }}></i>
                        </div>

                        {/* back arrow */}
                        <div>
                        {props.children}  
                        </div>  
                    
                        <div style={{ flexGrow: 1 }}></div>
                    
                        <div className='nav-right'>
                            <div className='bell_div mr-3 mr-lg-3'>
                                <img
                                className="imageStyle"
                                src={bell_icon}
                                alt="user"
                                />
                            </div>

                            <div>
                                <img src={user_p} alt='user' className='img-fluid user_p' />
                            </div>
                        </div>
                        
                    </div>
                </div>
           </nav> 
           
        </>
     );
}

const mapStateToProps = (state) =>{
    return{
        userRole: state.auth.role,
    }
  }

const mapDispatchToProps = (dispatch) =>{
    return{
        Logout: () => dispatch(logOut()),
    }
  }
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminSidebar);