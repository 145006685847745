const initState = {
    users: [],
    userSearch: [],
    user: {},
    loader: false,
}
  
  const userReducer = (state = initState, action) => {
    switch (action.type) {
      case "Users":
        return {
          ...state,
          users: action.data,
          userSearch: action.data,
          loader: false
        };
    case "filterUsers":
        const user = state.users.find((val) => val._id === action.data);
        return {
            ...state,
            user,
        };
     case 'Loader':
        return{
            ...state,
            loader: true
        } 
    case "searchUsers":
        let res = state.userSearch.filter((val) => {
            return (
            val.email.toLowerCase().includes(action.data.toLowerCase())
            ||
            val.firstName.toLowerCase().includes(action.data.toLowerCase())
            ||
            val.lastName.toLowerCase().includes(action.data.toLowerCase())
            );
     });
    return {
        ...state,
        users: res
     }
     case "UserSuccess":
        let result;
        switch (action.name) {
          case "Suspend":
            result = {
              ...state.user,
              isEnabled: false
            }
            break;
          case "Restore":
            result = {
              ...state.user,
              isEnabled: true,
            };
            break;
          default:
            break;
        }
     return {
        ...state,
        user: result,
     };
    default:
         return state;
      }
    };

export default userReducer;