import {GetApi, PatchApi} from '../request'
import cogoToast from 'cogo-toast';
import axios from 'axios';
import { apiUrl } from '../config';

const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  };
  

// get all giftcards trade
export const getGiftCardsTrade = (status, from_date, to_date) => {
  return async (dispatch, getState) => {
      dispatch({ type : 'Loader'})
    try {
      const res = await GetApi(`trade/giftcard/trade/all?status=${status}`, getToken(),);
      if (res.status === 200) {
        dispatch({ type: "Trades", data: res.data.message});
      }
      if(res.status === 400){
        dispatch({ type: "Trades_Error", err: res.data });
      }
    } catch (err) {
     console.log(err)
    }
  };
};

// get lastest trades
export const getLatestTrades = () => {
  return async (dispatch, getState) => {
    try {
      const res = await GetApi(`trade/giftcard/trade/all?status=All&limit=5`, getToken(),);
      if (res.status === 200) {
        dispatch({ type: "LatestTrades", data: res.data.message});
      }
      if(res.status === 400){
        dispatch({ type: "LatestTrades_Error", err: res.data });
      }
    } catch (err) {
     console.log(err)
    }
  };
};

// get filtered giftcard trades
export const getFilteredGiftCardsTrade = (status, from_date, to_date) => {
    return async (dispatch, getState) => {
        dispatch({ type : 'Loader'})
      try {
        const res = await GetApi(`trade/giftcard/trade/all?status=${status}&from_date=${from_date}&to_date=${to_date}`, getToken(),);
        if (res.status === 200) {
          dispatch({ type: "Trades", data: res.data.message});
        }
        if(res.status === 400){
          dispatch({ type: "Trades_Error", err: res.data });
        }
      } catch (err) {
       console.log(err)
      }
    };
  };


  // Search trades by amount
export const searchTrade = (val) =>{
    return dispatch =>{
        dispatch({type: 'searchTrade', data: val})
    }
  }

  export const approveTrade = (id) => {
    return async (dispatch, getState) => {
        dispatch({ type: 'START' });
      try {
        const res = await PatchApi(`trade/giftcard/approve/${id}`, "", getToken(), "application/json")
        if (res.status === 200) {
          dispatch({ type: 'Approve_SUCCESS' });
          cogoToast.success('Trade approved successfully!')
        }
        if(res.status === 400){
          // invalid trade
          dispatch({ type: 'Approve_ERROR' });
          cogoToast.error('Ooops!,an error occured while approving trade')
        }
      } catch (err) {
        console.log(err)
      }
    };
  };

  export const declineTrade = (creds) => {
    return async (dispatch, getState) => {
        dispatch({ type: 'START' });
        const values = {
          declinedReason:{
            imageUrl: creds.imageUrl,
            reasonText: creds.reason
          }
        }
      try {
        const res = await PatchApi(`trade/giftcard/decline/${creds.id}`, {
         ...values
        }, getToken(), "application/json")
        if (res.status === 200) {
          dispatch({ type: 'Decline_SUCCESS' });
          cogoToast.success('Trade declined successfully!')
        }
        if(res.status === 400){
          // invalid trade
          dispatch({ type: 'Decline_ERROR' });
          cogoToast.error('Ooops!,an error occured while declining trade')
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: 'Decline_ERROR' });
      }
    };
  };


  export const uploadImage = (file) => {
    return async (dispatch, getState) => {
      dispatch({ type: 'STARTUPLOAD' });
      const formData = new FormData();
      formData.append("file", file);
      try {
        const res = await axios({      
            method: "post",
            url: `${apiUrl}files/upload`,
            data: formData,
            headers: { 
              "Content-Type": "multipart/form-data",
              "Authorization": getToken()
            },
          
        })
        if (res.status === 201) {
          dispatch({ type: 'STOPUPLOAD' });
          dispatch({ type: 'ImageSuccess', data: res.data });
          cogoToast.success('Image uploaded successfully!')
        }
        if(res.status === 400){
          // invalid file
          dispatch({ type: 'STOPUPLOAD' });
          dispatch({ type: 'Image_ERROR' });
          cogoToast.error('Ooops!,an error occured while uploading trade')
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: 'STOPUPLOAD' });
      }
    };
  };


  // clear image success
  export const clearImageSuccess = () =>{
    return dispatch =>{
        dispatch({type: 'clearImageSuccess'})
    }
  }

   // clear clearRequest
 export const clearRequest = () =>{
    return dispatch =>{
        dispatch({type: 'clearRequest'})
    }
  }

