import React from 'react';
import UsersSidebar from '../../../components/AdminSidebar/sidebar';
import styles from './profile.module.css'
import user from '../../../assets/images/userProfile.svg'
import reverse_icon from '../../../assets/icons/reverse_icon.svg'
import { Form, Formik } from 'formik'
import { connect } from 'react-redux';
import Moment from "react-moment";
import { ChangePasswordValidator } from '../../../validationSchema/validator'
import { ChangePassword } from '../../../store/actions/auth';



const AdminProfile = ({phoneNumber, firstName, lastName, email, createdAt,savePassword}) => {

      // submit func
      const handleSubmit = async (values) => {
          console.log(values)
        await savePassword(values)
      }


    return ( 
        <>
            <UsersSidebar>
                <p className="titlehead">Profile</p>
            </UsersSidebar>
            <div className="usermain">
                 <div className="contain">

                        <div className={`mt-5 ${styles.profileCard}`}>
                            <div className={styles.firstHalf}>
                                <div className={styles.userImg}>
                                    <img src={user} className='img-fluid' alt="user" />
                                </div>
                                <div className={`mt-3 ${styles.changeImg_div}`}>
                                    <img src={reverse_icon} width='20' height='20' className='img-fluid' alt="reverse" />
                                    <p className={styles.changeImg_text}>Change Image</p>
                                </div>
                                <div className={`mt-3 ${styles.profileHr}`}>
                                </div>
                                <div className={`mt-3 ${styles.profileName_Div}`}>
                                    <h6 className={styles.name}>{firstName ? firstName : 'N/A'} {lastName ? lastName : 'N/A'}</h6>
                                    <p className={`mt-1 mb-0 ${styles.date}`}>{email ? email : 'N/A'}</p>
                                    <p className={`mt-1 mb-0 ${styles.date}`}>{phoneNumber ? phoneNumber : 'N/A'}</p>
                                    <p className={`mb-0 mt-1 ${styles.date}`}>Joined: <Moment format="MMMM Do, YYYY">{createdAt}</Moment></p>
                                </div>
                                
                             
                            </div>

                            <div className={styles.secondHalf}>
                            <h6 className={styles.title}>Change Password</h6>
                               <div>
                                {/* form submission */}
                                <Formik
                                onSubmit={(values, { setSubmitting }) =>
                                handleSubmit(values, setSubmitting)
                                }
                                validationSchema={ChangePasswordValidator}
                                initialValues={{
                                  password: "",
                                  newpassword: "",
                                  confirm_password: "",
                                }}
                            >
                                {({
                                handleChange,
                                isSubmitting,
                                handleSubmit,
                                handleBlur,
                                values,
                                touched,
                                errors,
                                }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className={styles.form_div}>
                                    <div
                                        className={`mt-3 mb-3 ${styles.input_container}`}
                                    >
                                        <label htmlFor="password">
                                        Current Password
                                        </label>
                                        <div>
                                        <input
                                            name="password"
                                            type='password'
                                            placeholder="Enter your current password"
                                            className={styles.input_style}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.password}
                                        />
                                        </div>
                                        <small style={{ color: '#dc3545' }}>
                                        {touched.password &&
                                            errors.password}
                                        </small>
                                    </div>

                                    <div className={`mb-3 ${styles.input_container}`}>
                                        <label htmlFor="newpassword">New Password</label>
                                        <div>
                                        <input
                                            type="password"
                                            name="newpassword"
                                            placeholder="Enter a new password"
                                            className={styles.input_style}
                                            value={values.newpassword}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        </div>
                                        <small style={{ color: '#dc3545' }}>
                                        {touched.newpassword && errors.newpassword}
                                        </small>
                                    </div>

                                    <div className={`mb-3 ${styles.input_container}`}>
                                        <label htmlFor="confirm_password">Confirm Password</label>
                                        <div>
                                        <input
                                            type="password"
                                            name="confirm_password"
                                            placeholder="Confirm your password"
                                            className={styles.input_style}
                                            value={values.confirm_password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        </div>
                                        <small style={{ color: '#dc3545' }}>
                                        {touched.confirm_password && errors.confirm_password}
                                        </small>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className={`btn ${styles.btn_submit}`}
                                        >
                                        Change Password
                                        </button>
                                    </div>
                                    </div>
                                </Form>
                                )}
                            </Formik>
                               </div>

                            </div>

                        </div>

                  </div>
             </div>
        </>
     );
}

const mapStateToProps = (state) => {
    return {
        phoneNumber: state.auth.profile.phoneNumber,
        firstName: state.auth.profile.firstName,
        lastName: state.auth.profile.lastName,
        email: state.auth.profile.email,
        createdAt: state.auth.profile.createdAt,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      savePassword: (values) => dispatch(ChangePassword(values)),
    };
  }
   
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile);