import { combineReducers } from "redux";
import adminReducer from "./admin";
import authReducer from "./auth";
import giftrateReducer from "./giftrates";
import overviewReducer from "./overview";
import requestReducer from "./requests";
import tradeReducer from "./trades";
import transactionReducer from "./transactions";
import userReducer from "./users"
import settingsReducer from "./settings"


const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  trade: tradeReducer,
  transaction: transactionReducer,
  admin: adminReducer,
  giftrate: giftrateReducer,
  request: requestReducer,
  overview: overviewReducer,
  settings: settingsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "logout") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
  