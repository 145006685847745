import React,{useEffect, useState, useRef} from 'react';
import AdminSidebar from '../../../components/AdminSidebar/sidebar';
import styles from './trades.module.css'
import ImageZoom from "react-medium-image-zoom";
import 'react-medium-image-zoom/dist/styles.css'
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useParams, useNavigate } from 'react-router-dom';
import Moment from "react-moment";
import { approveTrade, clearImageSuccess, clearRequest, declineTrade, uploadImage } from '../../../store/actions/trades';
import Modal from "../../../components/Modals/declineModal";
import { Form, Formik } from "formik";
import { declineValidator } from "../../../validationSchema/validator";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { connect } from 'react-redux';



const AdminTradeDetailsPage = ({trades, loader, handleClear, requestSuccess, handleApprove, handleUpload, imageAdded, imageLoader, handleClearImage, handleDecline}) => {

  const {id} = useParams()

  const navigate = useNavigate();

  const trade = trades.find((val) => val._id === id);

  const [showDeclineModal, setDeclineModal] = useState(false);

      // mapping images
      const imageLayout = trade ?  trade.imageUrl
      .filter((el) => el !== "")
      .map((item, index) => (
          <div key={index} className='col-lg-4 mb-3'>
           <div>
              <ImageZoom>
                  <img
                  alt="cards"
                  src={item}
                  className={styles.cardImage}
                  />
              </ImageZoom>
           </div>
          </div>
      ))
      :
      ""

    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result =  styles.pending_badge
            break;
          case "Completed":
            result = styles.success_badge
            break;
          case "Declined":
            result =  styles.failed_badge
            break;
          default:
            break;
        }
        return result;
      };


      const Approve = () => {
        confirmAlert({
          title: "Confirm to submit",
          message: `You are about to approve this trade, Do you wish to continue?`,
          buttons: [
            {
              label: "Yes",
              onClick: () => TradeApprove(),
            },
            {
              label: "No",
            },
          ],
        });
      };

    
      const TradeApprove = () => {
        handleApprove(id)
      };
  
    
    const Decline = () => {
      setDeclineModal(true)
    };
  
    const fileInputRef = useRef();

    const fileobj= [];

    const changedHandler = () => {
      let files = fileInputRef.current.files;
      fileobj.push(files);
      handleUpload(files[0])
    }

    const handleSubmit = async (values) => {   
      const creds = {
          id,
          imageUrl: imageAdded.hasOwnProperty('fileUrl') ? imageAdded.fileUrl : "",
          reason: values.reason
      }
      console.log(creds)
      await handleDecline(creds)
    };


    const numberWithCommas = (x) => { 
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }

    useEffect(()=>{
      if(requestSuccess){
             setTimeout(()=>{
                 navigate('/trades')
                 handleClear()
                 handleClearImage()
             }, 2000)      
         }
     },[navigate, requestSuccess, handleClear, handleClearImage])


    return (  
        <>
            <AdminSidebar>
            <p className="titlehead">Trade Details</p>
            </AdminSidebar>
            <div className="usermain"> 
                 <div className="contain">


            <Modal
              title="Decline Trade"
              show={showDeclineModal}
              onClose={() => setDeclineModal(false)}
            >
               <Formik
                onSubmit={(values, { setSubmitting }) =>
                  handleSubmit(values, setSubmitting)
                }
                validationSchema={declineValidator}
                initialValues={{
                  reason: "",
                }}
              >
                {({
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>

                      <div className={`mb-3 ${styles.input_container}`}>
                          <label htmlFor="reason">Reason</label>
                          <div style={{ position: "relative" }}>
                              <textarea
                                  className={`${styles.input_style}`}
                                  type="text"
                                  placeholder="Enter a reason for decline"
                                  id="reason"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.reason}
                                  style={{width: '100%', resize: 'none'}}
                                  rows="6"
                              />
                            
                          </div>
                          <small style={{ color: "#dc3545" }}>
                              {touched.reason && errors.reason}
                          </small>
                      </div>

                      <div className={`mb-3 ${styles.input_container}`}>
                          <div className={`${styles.input_group} ${styles.cursor_pointer}`}>
                            <div className={styles.custom_file}>
                              <input accept="image/*"      
                                    ref={fileInputRef}  
                                  onChange={changedHandler}
                                  className={styles.custom_file_input} type="file" />
                              <label className={styles.custom_file_label}>
                                Attach an image
                              </label>
                          </div>
                          </div>         
                      </div> 

                      <div className={styles.fileupload}>
                        <div className="row">                            
                            <div className='col-4 mb-3'>
                              {imageLoader &&  <Skeleton count={1} style={{height: '100px'}} /> }
                             {
                               imageAdded.hasOwnProperty('fileUrl') ?
                                <div className={styles.image_preview}>
                                  <img src={imageAdded.fileUrl} className='img-fluid' alt="preview" />
                                </div>
                              :
                              ""
                             } 
                              
                            </div>
                        </div>                                    
                    </div>

                    <div className="text-center mt-5">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn ${styles.btn_filter}`}
                      >
                        Decline Trade
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>

            </Modal>


                     <div className={`mt-5 ${styles.tradeCard}`}>
                        <div className={styles.requestWrapper}>
                          <div className={styles.firstHalf}>

                              <h6 className={`mb-0 ${styles.cardTitle}`}>Giftcard Images</h6>

                              {/* cards */}
                              <div className='row mt-lg-5 mt-4'>
                                {
                                    imageLayout
                                  }
                              </div>

                              {
                                trade.tradeStatus === 'Declined' &&
                                <>
                                  <div className='mt-4 mb-4'>
                                      <h6 className={styles.cardTitle}>Declined Reason</h6>
                                      <p className={styles.info_subtitle}>{trade.hasOwnProperty('declinedReason') ? trade.declinedReason.reasonText : "Declined"}</p>
                                  </div>
                                    
                                  <div className='mb-4'>
                                      <h6 className={styles.cardTitle}>Image(s) Attached for Declination</h6>
                                      <ImageZoom>
                                          <img
                                          alt="cards"
                                          src={trade.declinedReason.imageUrl === "" ? trade.imageUrl[0] : trade.declinedReason.imageUrl }
                                          className={`mt-2 ${styles.cardImage}`}
                                          />
                                      </ImageZoom>
                                  </div>
                                </>
                              }

                          </div>

                          <div className={styles.secondHalf}>
                                  <div className='mb-4'>
                                      <h6 className={`mb-0 ${styles.info_title}`}>Transaction Id</h6>
                                      <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{trade ? trade._id: 'trans1445555'}</p>
                                  </div>

                                  <div className='mb-4'>
                                      <h6 className={`mb-0 ${styles.info_title}`}>Trade Type</h6>
                                      <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>Giftcard</p>
                                  </div>

                                  <div className='mb-4'>
                                      <h6 className={`mb-0 ${styles.info_title}`}>Card Name</h6>
                                      <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{trade ? trade.subCategoryDetails.categoryname: 'N/A'}</p>
                                  </div>

                                  <div className='mb-4'>
                                      <h6 className={`mb-0 ${styles.info_title}`}>Card Category</h6>
                                      <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{trade ? trade.subCategoryDetails.subcategoryname: 'N/A'}</p>
                                  </div>

                                  <div className='mb-4'>
                                      <h6 className={`mb-0 ${styles.info_title}`}>Comment</h6>
                                      <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{
                                        trade && trade.comment !== "" ? trade.comment
                                        : trade && trade.comment === "" ? 'No comment'
                                          : 'N/A'
                                        }</p>
                                  </div>

                                  <div className='mb-4'>
                                      <h6 className={`mb-0 ${styles.info_title}`}>Amount Traded</h6>
                                      <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{trade ? trade.cardAmount : 'N/A'} USD</p>
                                  </div>

                                  <div className='mb-4'>
                                      <h6 className={`mb-0 ${styles.info_title}`}>Date Initialized</h6>
                                      <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>
                                      <Moment format="MMMM Do, YYYY, h:mm:ss a">
                                        {trade ? trade.createdAt : "2022-06-06T14:25:01.549Z"}
                                        </Moment>
                                      </p>
                                  </div>

                                  <div className='mb-4'>
                                      <h6 className={`mb-0 ${styles.info_title}`}>Amount To Get</h6>
                                      <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{trade ? numberWithCommas(trade.amount) : 'N/A'} Naira</p>
                                  </div>

                                  <div>
                                      <h6 className={`mb-0 ${styles.info_title}`}>Trade Status</h6>
                                      <div className='d-flex'>
                                        <p className={`mt-2 ${styles.info_subtitle}  ${getStatusColor(trade ? trade.tradeStatus : 'Pending')}`}>
                                        {trade? trade.tradeStatus: 'N/A'}
                                        </p>
                                      </div>
                                  </div>

                          </div>
                        </div>
                    
                        {
                         trade.tradeStatus === 'Pending' &&
                          <div className={`mt-5 ${styles.cta_btn}`}>
                                  <button
                                      type="submit"
                                      className={`btn mb-3 mb-lg-0 ${styles.btn_approve}`}
                                      onClick={() => {
                                        Approve();
                                        }}
                                        disabled={loader}  
                                  >
                                      Approve Trade
                                  </button>
                                  <button
                                      type="submit"
                                      className={`btn ${styles.btn_decline}`}
                                      disabled={loader}
                                      onClick={() => {
                                        Decline();
                                        }}
                                  >
                                      Decline Trade
                                  </button>
                          </div>
                         }

                      </div>



                  </div>
           </div>
        </>
    );
}

const mapStateToProps = (state) => {
  return {
    trades: state.trade.trades,
    loader: state.trade.load,
    requestSuccess: state.trade.requestSuccess,
    imageAdded: state.trade.imageAdded,
    imageLoader: state.trade.imageLoader
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      handleApprove : (id) => dispatch(approveTrade(id)),
      handleDecline : (id) => dispatch(declineTrade(id)),
      handleUpload : (file) => dispatch(uploadImage(file)),
      handleClear : () => dispatch(clearRequest()),
      handleClearImage : () => dispatch(clearImageSuccess()),
  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminTradeDetailsPage);