const initState = {
    isAuthenticated: false,
    token: "",
    profile: {},
    role: "",
    profilePic: "",
    isSetPin: false,
    reset_success: false,
    resetcode: false,
}
  
  const authReducer = (state = initState, action) => {
    switch (action.type) {
      case "LOGIN_SUCCESS":
        localStorage.setItem("token", action.data.token);
        return {
          ...state,
          isAuthenticated: true,
          token: action.data.token,
          profile: action.data.profile,
          role: action.data.profile.role,
          profilePic: action.data.profile.profilePic,
          isSetPin: action.data.profile.isSetPin,
        };
        case "VALID_RESETCODE":
          return {
            ...state,
            resetcode: false,
          };
       case "INVALID_RESETCODE":
          return {
            ...state,
            resetcode: true,
        }; 
      case "PasswordSuccess":
        return{
          ...state,
          reset_success: true
        } 
      case "logout":
        return {
          ...state,
          isAuthenticated: false,
        };
      default:
        return state;
    }
  };
  
  export default authReducer;
  