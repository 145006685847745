const initState = {
    transactions: [],
    transactionsSearch: [],
    loader: false
  };
  
  const transactionReducer = (state = initState, action) => {
    switch (action.type) {
      case 'Transaction':
          return {
                ...state,
                transactions: action.data,
                transactionsSearch: action.data,
                loader: false
            }
        case "searchTransaction":
                let res = state.transactionsSearch.filter((val) => {
                    return (
                    val.type.toLowerCase().includes(action.data.toLowerCase())
                    ||
                    val.amount.toString().includes(action.data.toString())
                    );
                });
            return {
                ...state,
                transactions: res
         }
        case 'Loader':
            return{
                ...state,
                loader: true
            }
        case 'Transaction_Error':
            return{
                ...state,
                loader: false
            }
      default:
        return state;
    }
  };
  
  export default transactionReducer;
  