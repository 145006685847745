const initState = {
    trades: [],
    tradesSearch: [],
    loader: false,
    load: false,
    requestSuccess: false,
    imageAdded: {},
    imageLoader: false,
    latestTrades: []
  };
  
  const tradeReducer = (state = initState, action) => {
    switch (action.type) {
      case 'Trades':
          return {
                ...state,
                trades: action.data,
                tradesSearch: action.data,
                loader: false
            }
       case "searchTrade":
            let res = state.tradesSearch.filter((val) => {
                return (
                val.tradeStatus.toLowerCase().includes(action.data.toLowerCase())
                ||
                val.subCategoryDetails.categoryname.toLowerCase().includes(action.data.toLowerCase())
                );
            });
        return {
            ...state,
            trades: res
        }
        case 'LatestTrades':
            return{
                ...state,
                latestTrades: action.data
         }
        case 'Loader':
            return{
                ...state,
                loader: true
        }
       case 'START':
          return{
            ...state,
            load: true
        }
        case 'Approve_SUCCESS':
            return{
                ...state,
                load: false,
                requestSuccess: true
            }
      case 'Approve_ERROR':
            return{
                ...state,
                load: false
         }
     case 'Decline_SUCCESS':
        return{
            ...state,
            load: false,
            requestSuccess: true
        }
      case 'Decline_ERROR':
        return{
            ...state,
            load: false
        }
    case 'clearRequest':
        return{
            ...state,
            requestSuccess: false
        }
    case 'STARTUPLOAD':
        return{
            ...state,
            imageLoader: true
        }
    case 'STOPUPLOAD':
        return{
            ...state,
            imageLoader: false
        }
    case "ImageSuccess":
        return{
            ...state,
            imageAdded: action.data
        }
    case 'clearImageSuccess':
        return{
            ...state,
            imageAdded: {}
      }
    default:
            return state;
     }
 };


 export default tradeReducer;