import React,{useEffect} from 'react';
import AdminSidebar from '../../../components/AdminSidebar/sidebar';
import styles from './overview.module.css'
import arrow_right from '../../../assets/icons/chevron_right.svg'
import earnings from '../../../assets/icons/earnings.svg'
import income_icon from '../../../assets/icons/income_icon.svg'
import withdraw_icon from '../../../assets/icons/withdraw_icon.svg'
import networth from '../../../assets/icons/networth.svg'
import check_circle from '../../../assets/icons/check-circle.svg'
import DataTable from "react-data-table-component";
import { useNavigate } from 'react-router-dom';
import DoughnutChart from '../../../components/Charts/Doughnut';
import { getDashboardAnalytics } from '../../../store/actions/overview';
import { connect } from 'react-redux';
import CountUp from "react-countup";
import { getAllRequestWithLimit } from '../../../store/actions/requests';
import Moment from "react-moment";
import { getLatestTrades } from '../../../store/actions/trades';


const AdminOverviewPage = ({ fetchRequest ,requests,
                            fetchLatestTrades,latestTrades,
                         loading,  fetchOverviewData, totalOutflow,
                         pendingWithdrawerRequest, allUsersCount, completedTrade}) => {

    const navigate = useNavigate()

    useEffect(()=>{
        fetchRequest()
        fetchLatestTrades()
        fetchOverviewData()
      },[fetchOverviewData, fetchRequest, fetchLatestTrades])

      const columns = [
        {
            name: 'Name',
            cell: (row) => (
                <span className={styles.recent_table_row}>{`${row.userDetails.firstName} ${row.userDetails.lastName}`}</span>
            ),
        },
        {
            name: 'Account Number',
            cell: (row) => (
                <span className={styles.recent_table_row}>{`${row.accountNumber}`}</span>
              ),
        },
        {
            name: 'Amount',
            cell: (row) => (
                <span className={styles.recent_table_row}>{`NGN ${row.amount}`}</span>
              ),
        },
        {
            name: 'Date Requested',
            cell: (row) => (
                <span className={styles.recent_table_row}>
                    <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
                </span>
              ),
        },
        {
            name: 'Status',
            cell: (row) => (
                <span className={getStatusColor(row.status)}>{`${row.status}`}</span>
            ),
        },
        {
            name: "Action",
            button: true,
            cell: (row) => (
              <button
              className="btn btn-sm btn-view"
              onClick={() => {
                ViewRequest(row._id);
              }}
            >
              View
            </button>
            ),
          },
     ];

    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result = "pending_icon";
            break;
          case "Successful":
            result = "completed_icon";
            break;
          case "Failed":
            result = "failed_icon";
            break;
          default:
            result = "failed_icon";
            break;
        }
        return result;
      };


    const ViewRequest = (id) =>{
        navigate(`/request/details/${id}`)
    }

    const handleCompleted = ()=>{
        navigate('/trades')
    }

    const handleUsers = () =>{
        navigate('/users')
    }

    const handleTradeDetails = (id) =>{
        navigate(`/trade/details/${id}`)
    }

    return ( 
        <>
            <AdminSidebar>
                 <p className="titlehead">Overview</p>
            </AdminSidebar>
            <div className="usermain">
                 <div className="contain">

                      <div className='row mt-5'>
                         <div className='col-lg-6 mb-lg-0 mb-5'>

                             <div className={styles.summarytitle_div}>
                                 <h6 className='mb-0'>Summary</h6>
                                 <img src={arrow_right} width='8' height='8' className='img-fluid' alt="arrow" />
                             </div>

                             <div className='row mt-5'>
                                 <div className='col-lg-6 mb-4 mb-lg-5'>
                                     <div className={styles.walletCard} onClick={handleCompleted}>
                                         <div className={styles.contentDiv}>
                                             <div className={styles.withdrawImgDiv} style={{marginRight: '20px'}}>
                                                 <img src={withdraw_icon} className='img-fluid'  alt='withdraw' />
                                             </div>
                                             <div>
                                                 <p className={styles.title}>Completed Trade(s)</p>
                                                 <h6 className={`mt-1 mb-0 ${styles.balance}`}>{completedTrade}</h6>
                                                 <p className={`mb-0 mt-1 ${styles.duration}`}>Last Updated</p>
                                             </div>
                                         </div>
                                     </div>

                                 </div>

                                 <div className='col-lg-6 mb-5 mb-lg-5'>
                                      <div className={styles.walletCard} onClick={handleUsers}>
                                         <div className={styles.contentDiv}>
                                             <div  className={styles.incomeImgDiv} style={{marginRight: '20px'}}>
                                                 <img src={income_icon} className='img-fluid' alt='income' />
                                             </div>
                                             <div>
                                                 <p className={styles.title}>Users</p>
                                                 <h6 className={`mt-1 mb-0 ${styles.balance}`}>{allUsersCount}</h6>
                                                 <p className={`mb-0 mt-1 ${styles.duration}`}>Last Updated</p>
                                             </div>
                                         </div>

                                     </div>

                                 </div>

                                 <div className='col-lg-6 mb-5 mb-lg-0'>
                                      <div className={styles.noBgCard}>
                                         <div className={styles.contentDiv}>
                                             <div className={styles.balanceImg}>
                                                 <img src={networth} className='img-fluid' alt='networth' />
                                             </div>
                                             <div>
                                                 <p className={styles.title}>Total Outflow</p>
                                                 <h5 className={`mt-1 mb-0 ${styles.balance}`}>₦
                                                 <CountUp
                                                    start={0}
                                                    end={totalOutflow ? totalOutflow : 0}
                                                    duration={2.5}
                                                    separator=","
                                                /></h5>
                                             </div>
                                         </div>

                                     </div>

                                 </div>

                                 <div className='col-lg-6 mb-lg-0 mb-4'>
                                      <div className={styles.noBgCard}>
                                         <div className={styles.contentDiv}>
                                             <div className={styles.contentImg}>
                                                 <img src={earnings} className='img-fluid' alt='earnings' />
                                             </div>
                                             <div>
                                                 <p className={styles.title}>Pending Request(s)</p>
                                                 <h5 className={`mt-1 mb-0 ${styles.balance}`}>{pendingWithdrawerRequest}</h5>
                                             </div>
                                         </div>

                                     </div>

                                 </div>

                             </div>


                         </div>

                         <div className='col-lg-6'>

                            <div className={styles.summarytitle_div}>
                                 <h6 className='mb-0'>Latest Trades</h6>
                                 <img src={arrow_right} width='8' height='8' className='img-fluid' alt="arrow" />
                             </div>

                             <div className='mt-5'>
                             {(
                                 latestTrades.length > 0 ? latestTrades.map((item)=>(
                                    <div key={item._id} className={`mb-3 ${styles.latest_div}`} onClick={()=> handleTradeDetails(item._id)}>
                                    <div className={styles.latest_card}>
                                        <div className={styles.latest_cardImg}>
                                            <img src={check_circle} alt="check" className='img-fluid' />
                                        </div>
                                        <div>
                                            <h6 className={`mb-0 ${styles.latestcard_title}`}>{item.subCategoryDetails.categoryname}</h6>
                                            <p className={`mb-0 mt-1 ${styles.latestcard_subtitle}`}>
                                                 <Moment format="MMMM Do, YYYY">{item.createdAt}</Moment>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                    <p className={`mb-0 ${styles.latestcard_amt}`}>USD {item.cardAmount}</p>
                                    </div>
                                </div>
                                 ))
                                 :
                                 <div className='text-center'>
                                        <p>You do not have any trade(s) yet!</p>
                                </div>
                             )}
                              
                             </div>


                        </div>

                     </div>

                     <div className='row mt-4 mt-lg-5'>
                         <div className='col-lg-8 mb-lg-0 mb-4'>
                             <div className={styles.pendingTable}>
                                <DataTable
                                    title="Recent Requests"
                                    pagination
                                    persistTableHead
                                    columns={columns}
                                    data={requests}
                                    progressPending={loading}
                                />
                             </div>
                             
                         </div>

                         <div className='col-lg-4'>

                            <div className={`${styles.analytics_div}`}>
                                <h6 className={styles.analytics_title}>Analytics</h6>
                                <DoughnutChart />
                            </div>

                         </div>

                     </div>

                    

                    </div>
            </div>
        </>
     );
}


const mapStateToProps = (state) => {
    return {
        latestTrades: state.trade.latestTrades,
        requests: state.request.requests,
        loading: state.request.loading,
        totalOutflow: state.overview.totalOutflow,
        pendingWithdrawerRequest: state.overview.pendingWithdrawerRequest,
        completedTrade: state.overview.completedTrade,
        allUsersCount: state.overview.allUsersCount,
        
    }
  }

const mapDispatchToProps = (dispatch) => {
    return {
      fetchRequest: () => dispatch(getAllRequestWithLimit()),
      fetchOverviewData: () => dispatch(getDashboardAnalytics()),
      fetchLatestTrades: () => dispatch(getLatestTrades()),
    };
  }
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminOverviewPage);