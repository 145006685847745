import React from 'react';
import AdminSidebar from '../../../components/AdminSidebar/sidebar';
import styles from './users.module.css'
import bank_icon from '../../../assets/icons/bank.svg'
import check_icon from '../../../assets/icons/check-circle(green).svg'
import { connect } from 'react-redux';
import Moment from "react-moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ActivateUser, SuspendUser } from '../../../store/actions/users';


const AdminUserInfoPage = ({user, details, HandleSuspend, HandleActivate}) => {

    const getStatusColor = (val) => {
        let result;
        switch (val) {
            case Boolean("true"):
              result = styles.success_badge
              break;
            case Boolean("false"):
              result = styles.failed_badge
              break;
            default:
              break;
          }
        return result;
      };

      const Suspend = (id, status) => {
        confirmAlert({
          title: "Confirm to submit",
          message: `You are about to ${
            status ? "suspend" : "restore"
          } this user, Do you wish to continue?`,
          buttons: [
            {
              label: "Yes",
              onClick: () => Approval(id, status),
            },
            {
              label: "No",
            },
          ],
        });
      };

    // function to restore or suspend
    const Approval = (id, status) => {
        const values = {
          userId: id,
        };
        if (status) {
          HandleSuspend(values);
        } else {
          HandleActivate(values);
        }
      };

    return ( 
        <>
            <AdminSidebar>
                <p className="titlehead">User Information</p>
            </AdminSidebar>  
            <div className="usermain">
                 <div className="contain">
                    <div className={`mt-5 ${styles.tradeCard}`}>


                         <div className={styles.firstHalf}>        

                         <h6 className={`mb-0 ${styles.cardTitle}`}>Bank Details</h6>

                         {
                             details && details.length > 0 ?
                             details.map((value) => {
                                return (
                                <div className={`mt-4 mb-4 ${styles.accountDetailsDiv}`}>
                                    <div className={`d-none d-lg-block ${styles.accountDetailsIcon}`}>
                                         <img src={check_icon} className='img-fluid' alt="check" />
                                    </div>
        
                                    <div className={`${styles.accountDiv}`}>
                                            <div className={styles.accountInfo}>
                                                <div className={styles.accountIcon}>
                                                     <img src={bank_icon} alt="bank" className='img-fluid' />
                                                </div>
                                                <div>
                                                    <h6 className={`mb-0 ${styles.accountNm}`}>{value.accountName}</h6>
                                                    <p className={`mb-0 mt-1 ${styles.accountNo}`}>{value.accountNumber}</p>
                                                    <p className={`mb-0 mt-1 ${styles.bankTitle}`}>{value.bankName}</p>
                                                </div>
                                            </div>    
                                    </div>
                                  </div>
                                );
                              })
                             : (
                              <div>
                              </div>
                            )
                         }

                        {details && details.length === 0 ? (
                            <div
                            className="text-center mt-5"
                            style={{ fontStyle: "italic", fontSize: 14 }}
                            >
                            <p>There is no account details attached yet!</p>
                            </div>
                                ) : (
                            ""
                         )}


                         </div>

                         <div className={styles.secondHalf}>
                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Firstname</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{user.firstName ? user.firstName : 'Nill'}</p>
                                </div>

            
                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Lastname</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{user.lastName ? user.lastName : 'Nill'}</p>
                                </div>

                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Email Address</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{user.email ? user.email : 'your@email.com'}</p>
                                </div>

                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Phone Number</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{user.phoneNumber ? user.phoneNumber : '080 000 0000'}</p>
                                </div>

                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Wallet Balance</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>NGN {user.waletBalance ? user.waletBalance.toFixed(2) : 0}</p>
                                </div>

                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Date Joined</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>
                                       <Moment format="MMMM Do, YYYY">{user.createdAt}</Moment>
                                     </p>
                                </div>

                                <div>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Status</h6>
                                    <div className='d-flex'>
                                      <p className={`mt-2 ${styles.info_subtitle} ${getStatusColor(user.isEnabled)}`}>
                                        {user.isEnabled ? "Active" : "Inactive"}
                                      </p>
                                     </div>
                                </div>

                                <div className="mt-4">
                                    <button
                                        type="submit"
                                        className={
                                            user.isEnabled
                                              ? `btn ${styles.suspend_btn}`
                                              : `btn ${styles.restore_btn}`
                                          }
                                        onClick={() => {
                                            Suspend(user._id, user.isEnabled);
                                        }}
                                    >
                                        {user.isEnabled ? "Suspend User" : "Restore User"}
                                    </button>
                                </div>
                         </div>
                      </div>
                     
                </div>
            </div>
        </>
     );
}

const mapStateToProps = (state) => {
    return {
      user: state.user.user,
      details: state.user.user.accountDetails,
    };
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
     HandleSuspend: (value) => dispatch(SuspendUser(value)),
     HandleActivate: (value) => dispatch(ActivateUser(value)),
    };
  }
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminUserInfoPage);