import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import LoginPage from './pages/Auth/login'
import ForgotPassword from './pages/Auth/forgot-password'

// private routes
import AdminRoute from "./components/PrivateRoutes/adminRoute";

import NotFound from './pages/NotFound/NotFound'
import AdminOverviewPage from './pages/Admin/Overview/overview';
import AdminProfile from './pages/Admin/Profile/profile';
import AdminTradesPage from './pages/Admin/Trades/trades';
import AdminTradeDetailsPage from './pages/Admin/Trades/tradeDetails';
import AdminTransactionsPage from './pages/Admin/Transactions/transactions';
import AdminTransactionDetailsPage from './pages/Admin/Transactions/transactionDetails';
import AdminRequestsPage from './pages/Admin/Requests/requests';
import AdminRequestDetailsPage from './pages/Admin/Requests/requestDetails';
import AdminUsersPage from './pages/Admin/Users/users';
import AdminUserInfoPage from './pages/Admin/Users/userInfo';
import AdminRatesPage from './pages/Admin/Rates/rates';
import AdminEditRatePage from './pages/Admin/Rates/editRate';
import AdminManagePage from './pages/Admin/Admin/admin';
import SettingsPage from './pages/Admin/Settings/settings';
import ResetPassword from './pages/Auth/reset-password';

function App() {
  return (
      <>
         <Router>
          <Routes>
            <Route exact path="/" element={<LoginPage />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/overview" element={
              <AdminRoute>
               <AdminOverviewPage />
              </AdminRoute>
            }
            />
            <Route path="/trades" element={
                   <AdminRoute>
                    <AdminTradesPage />
               </AdminRoute>
              }
            />
            <Route path="/trade/details/:id"
             element={
              <AdminRoute>
                <AdminTradeDetailsPage />
               </AdminRoute>
            } />  
             <Route path="/transactions" element={
                   <AdminRoute>
                    <AdminTransactionsPage />
               </AdminRoute>
              }
            />
             <Route path="/transaction/:id" element={
                <AdminRoute>
                   <AdminTransactionDetailsPage />
               </AdminRoute>
              }
            />
              <Route path="/requests" element={
                 <AdminRoute>
                   <AdminRequestsPage />
               </AdminRoute>
              }
            />
             <Route path="/request/details/:id" element={
                <AdminRoute>
                  <AdminRequestDetailsPage />
               </AdminRoute>
              }
            />
             <Route path="/users" element={
               <AdminRoute>
               <AdminUsersPage />
               </AdminRoute>
              }
            />
             <Route path="/user/:id" element={
               <AdminRoute>
                 <AdminUserInfoPage />
               </AdminRoute>
              }
            />
              <Route path="/rates" element={
                <AdminRoute>
                   <AdminRatesPage />
               </AdminRoute>
              }
            />
             <Route path="/rate/edit/:id" element={
               <AdminRoute>
                 <AdminEditRatePage />
               </AdminRoute>
              }
            />

            <Route path="/admin" element={
               <AdminRoute>
                 <AdminManagePage />
               </AdminRoute>
              }
            /> 

             <Route path="/settings" element={
               <AdminRoute>
                <SettingsPage />
               </AdminRoute>
              }
            />  
           <Route path="/profile" element={
               <AdminRoute>
                 <AdminProfile />
               </AdminRoute>
              }
            />


            <Route path="*" element={<NotFound />} />
          </Routes>
         </Router>
      </>
  );
}

export default App;
