import cogoToast from "cogo-toast";
import { PostApi } from "../request";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

// login admin actions functionality
export const loginAdmin = (user) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi(`auth/admin/login`, { ...user }, "", "application/json");
      if (res.status === 200) {
        var role = res.data.message.profile.role;
         switch (role) {
           case "Admin":
             dispatch({ type: "LOGIN_SUCCESS", data: res.data.message });
             cogoToast.success("Login Successful!", {
               position: "bottom-right",
             });
            break;
          case "SubAdmin":
             dispatch({ type: "LOGIN_SUCCESS", data: res.data.message });
             cogoToast.success("Login Successful!", {
               position: "bottom-right",
             });
            break;
           default:
             cogoToast.error("User not authorized to access this page!");
             break;
         }       
      }
       if (res.status === 400) {
         cogoToast.error(`${res.data.message}`);    
       }
       if(res.status === 403){
        cogoToast.error("User not authorized to access this page!");
       }
    } catch (err) {
      console.log(err);
    }
  };
};


// verify forgot password code sent to email
export const verifyResetCode = (code) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi("auth/link/verify/forgotpasswordcode", {code}, "", "application/json")
      if (res.status === 200) {
        dispatch({ type: 'VALID_RESETCODE' });
      }
      if(res.status === 400){
        // Invalid code
        dispatch({ type: 'INVALID_RESETCODE' });
      }
    } catch (err) {
      console.log(err)
    }
  };
};

// reset password functionality
export const ResetUsersPassword = (val) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi(
        "auth/reset/password",
        { ...val },
        "",
        "application/json"
      );
      if (res.status === 200) {
        // reset a user's password
        dispatch({ type: 'PasswordSuccess' });
        cogoToast.success("Password successfully changed, Login to continue", {
          position: "top-center",
        });
      }
      if(res.status === 400){
        // error while reset password
        var msg = res.data.message;
        dispatch({ type: 'RESET_ERROR' });
        cogoToast.error(`${msg}`);
      }
    } catch (err) {
      console.log(err)
    }
  };
};


//   Change password functionality
export const ChangePassword = (user) => {
  return async (dispatch, getState) => {
    
    const values = {
      oldPassword: user.password,
      newPassword: user.newpassword
    }
    try {
      const res = await PostApi("auth/change/password", { ...values }, getToken(), "application/json");
      if (res.status === 200) {
         localStorage.setItem("token", "");
         dispatch({ type: "logout", err: "User Out" });
        cogoToast.success('Password updated successfully! Kindly Login again.', { position: 'bottom-right', })
      }
      if(res.status === 400){
        cogoToast.error('Check that your old password is correct!')
      }
    } catch (err) {
      console.log(err)
    }
  };
};

// forgot password functionality
export const forgotPassword = (user) => {
  return async (dispatch, getState) => {
     try {
       const res = await PostApi(`auth/forgot/password`, { ...user }, "", "application/json");
       if (res.status === 200) {
          cogoToast.success(
            "Check your email for password reset instructions!",
            {
              position: "top-center",
            }
          );
       }
        if (res.status === 400) {
          cogoToast.error(
            "Kindly check that the credentials entered is valid!"
          );
        }
     } catch (err) {
       console.log(err);
     }
  }
}

// logout a user
export const logOut = () => {
  return (dispatch, getState) => {
    localStorage.setItem("token", "");
    dispatch({ type: "logout", err: "User Out" });
  };
};
