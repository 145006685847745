import React,{useState, useEffect} from 'react';
import AdminSidebar from '../../../components/AdminSidebar/sidebar';
import styles from './admin.module.css'
import { Form, Formik } from "formik";
import { RegisterAdminValidator } from "../../../validationSchema/validator";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { connect } from 'react-redux';
import { ActivateAdmin, AddSubAdmin, getSubAdmin, SuspendAdmin } from '../../../store/actions/admin';

const AdminManagePage = ({loader, fetchAdmin, admin, addAdmin, HandleActivate, HandleSuspend,userRole}) => {
 
    const [tabData] = useState([
        { id: 1, name: "tab-1", text: "New Admin" },
        { id: 2, name: "tab-2", text: "View Admin" },
    ]);

    useEffect(()=>{
      fetchAdmin()
    },[fetchAdmin])

    const [initialTab, setTab] = useState(1);

        // tab layout
    const tabLayout = tabData.map((item) => (
        <div  key={item.id} 
        className={initialTab === item.id ? `${styles.activeTab} ${styles.tab}` : `${styles.tab}`}
            >
            <p onClick={() => handleToggle(item.id)} className='mb-0'>{item.text}</p>
        </div>
    ));

    const handleToggle = (id) => {
        setTab(id)
    }

    const handleSubmit = async (values) => {
        const creds = {
          role: 'SubAdmin',
          firstName: values.firstname,
          lastName: values.lastname,
          phoneNumber: values.phoneNumber,
          email: values.email,
          password: values.password
        }
        console.log(creds)
        await addAdmin(creds)
    };

  
    const columns = [
        {
          name: "Name",
          cell: (row) => (
            <span className={styles.recent_table_row}>{`${row.firstName} ${row.lastName}`}</span>
          ),
        },
        {
          name: "Phone Number",
          cell: (row) => (
            <span className={styles.recent_table_row}>{`${row.phoneNumber}`}</span>
          ),
        },
        {
          name: "Email Address",
          cell: (row) => (
            <span className={styles.recent_table_row}>
              {row.email}
            </span>
          ),
        },
        {
            name: "Date Registered",
            cell: (row) => (
              <span className={styles.recent_table_row}>
                 <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
              </span>
            ),
          },
        {
          name: "Status",
          cell: (row) => (
            <span>{`${row.isEnabled ? 'Active' : 'InActive'}`}</span>
          ),
        },
        {
          name: "Action",
          button: true,
          cell: (row) =>  (
            <button
            className="btn btn-sm btn-view"
            disabled={userRole === "SubAdmin"}
            onClick={() => {
              Suspend(row._id, row.isEnabled);
            }}
          >
            {row.isEnabled ? "Suspend" : "Restore"}
          </button>
          ),
        },
      ];
    
      const Suspend = (id, status) => {
        confirmAlert({
          title: "Confirm to submit",
          message: `You are about to ${
            status ? "suspend" : "restore"
          } this user, Do you wish to continue?`,
          buttons: [
            {
              label: "Yes",
              onClick: () => handleRequest(id, status),
            },
            {
              label: "No",
            },
          ],
        });
      };
    
      // function to restore or suspend
      const handleRequest = (id, status) => {
        const values = {
          userId: id,
        };
        if (status) {
          HandleSuspend(values);
        } else {
          HandleActivate(values);
        }
      };
    

    return ( 
        <>
            <AdminSidebar>
                 <p className="titlehead">Manage Admin</p>
            </AdminSidebar>
            <div className="usermain">
                 <div className="contain">

                 <div className={`mt-5 ${styles.TabHeading}`}>
                        {tabLayout}
                </div>

                {
                initialTab === 1 ?
                <div className={`mt-5 ${styles.admin_card}`}>

                     <h5 className={styles.title}>Create New Admin</h5>

                     <div className='mt-4'>

                              {/* form submission */}
                            <Formik
                            onSubmit={(values, { setSubmitting }) =>
                                handleSubmit(values, setSubmitting)
                            }
                            validationSchema={RegisterAdminValidator}
                            initialValues={{
                                firstname: "",
                                lastname: "",
                                email: "",
                                phoneNumber: "",
                                password: "",
                            }}
                            >
                            {({
                                handleChange,
                                isSubmitting,
                                handleSubmit,
                                handleBlur,
                                values,
                                touched,
                                errors,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                {/* current password */}
                                <div>
                                    <div
                                  className={`mb-3 ${styles.input_container}`}
                                    style={{ position: "relative" }}
                                    >
                                    <label htmlFor="firstname">First Name</label>
                                    <input
                                        type="text"
                                        name="firstname"
                                        placeholder="First Name"
                                        style={{ color: "black" }}
                                        className={`${styles.input_style}`}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        id="firstname"
                                        value={values.firstname}
                                    />

                                    <small style={{ color: "#dc3545" }}>
                                        {touched.firstname && errors.firstname}
                                    </small>
                                    </div>
                                </div>

                                {/* new password */}
                                <div>
                                    <div
                                    className={`mb-3 ${styles.input_container}`}
                                    style={{ position: "relative" }}
                                    >
                                    <label htmlFor="lastname">Last Name</label>
                                    <input
                                        type="text"
                                        className={`${styles.input_style}`}
                                        name="lastname"
                                        placeholder="Last Name"
                                        style={{ color: "black" }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        id="lastname"
                                        value={values.lastname}
                                    />

                                    <small style={{ color: "#dc3545" }}>
                                        {touched.lastname && errors.lastname}
                                    </small>
                                    </div>
                                </div>

                                <div>
                                    <div
                                    className={`mb-3 ${styles.input_container}`}
                                    style={{ position: "relative" }}
                                    >
                                    <label htmlFor="email">Email Address</label>
                                    <input
                                        type="email"
                                        className={`${styles.input_style}`}
                                        name="email"
                                        placeholder="Email Address"
                                        style={{ color: "black" }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        id="email"
                                        value={values.email}
                                    />

                                    <small style={{ color: "#dc3545" }}>
                                        {touched.email && errors.email}
                                    </small>
                                    </div>
                                </div>

                                <div>
                                    <div
                                    className={`mb-3 ${styles.input_container}`}
                                    style={{ position: "relative" }}
                                    >
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <input
                                        type="text"
                                        className={`${styles.input_style}`}
                                        name="phoneNumber"
                                        placeholder="Phone Number"
                                        style={{ color: "black" }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        id="phoneNumber"
                                        value={values.phoneNumber}
                                    />

                                    <small style={{ color: "#dc3545" }}>
                                        {touched.phoneNumber && errors.phoneNumber}
                                    </small>
                                    </div>
                                </div>

                                {/*  password */}
                                <div>
                                    <div
                                   className={`mb-3 ${styles.input_container}`}
                                    style={{ position: "relative" }}
                                    >
                                    <label htmlFor="email">Password</label>
                                    <input
                                        type="password"
                                        className={`${styles.input_style}`}
                                        name="password"
                                        placeholder="Enter Password"
                                        style={{ color: "black" }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        id="password"
                                        value={values.password}
                                    />

                                    <small style={{ color: "#dc3545" }}>
                                        {touched.password &&
                                        errors.password}
                                    </small>
                                    </div>
                                </div>

                                <div className="text-center mt-4">
                                    <button
                                    type="submit"
                                    disabled={isSubmitting || userRole === "SubAdmin"}
                                    className={`btn ${styles.btn_add}`}
                                    >
                                    Create New Admin
                                    </button>
                                </div>
                                </Form>
                            )}
                            </Formik>
                        </div>

                </div>
                :
                <>
                 <div className={`mt-5 ${styles.admintables_div}`}>
                    <DataTable
                    title="Administrators"
                    pagination
                    persistTableHead
                    columns={columns}
                    data={admin}
                    progressPending={loader}
                    />
                </div>
                </>
                }



                 </div>
            </div>
        </>
     );
}



const mapStateToProps = (state) =>{
  return{
      admin: state.admin.admin,
      loader: state.admin.loader,
      userRole: state.auth.role,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      fetchAdmin: () => dispatch(getSubAdmin()),
      addAdmin: (creds) => dispatch(AddSubAdmin(creds)),
      HandleSuspend: (value) => dispatch(SuspendAdmin(value)),
      HandleActivate: (value) => dispatch(ActivateAdmin(value)),
  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminManagePage);