import React,{useEffect, useState} from 'react'
import AdminSidebar from '../../../components/AdminSidebar/sidebar';
import styles from './users.module.css'
import DataTable from "react-data-table-component";
import { useNavigate } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import { filterUsers, getUsers, searchUsers } from '../../../store/actions/users';
import Moment from "react-moment";


const AdminUsersPage = ({fetchUsers, users, loader, searchUser, usersFilter}) => {

    const [search, setSearchValue] = useState("");

    const handleChange = (e) => {
        setSearchValue(e.target.value);
     };

    useEffect(() => {
        searchUser(search)
    }, [search, searchUser]);

    useEffect(()=>{
        fetchUsers()
     },[fetchUsers])


    const navigate = useNavigate();

    const columns = [
        {
            name: 'Name',
            cell: (row) => (
                <span className={styles.recent_table_row}>{`${row.firstName}`} {`${row.lastName}`}</span>
            ),
        },
        {
            name: "Email Address",
            cell: (row) => (
              <span className={styles.recent_table_row}>
                {row.email}
              </span>
            ),
          },
        {
            name: 'Phone Number',
            cell: (row) => (
                <span className={styles.recent_table_row}>{`${row.phoneNumber}`}</span>
            ),
        },
        {
            name: "Date Registered",
            cell: (row) => (
              <span className={styles.recent_table_row}>
                 <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
              </span>
            ),
        },
        {
            name: "Status",
            cell: (row) => (
              <span className={getStatusColor(row.isEnabled)}>{`${row.isEnabled ? 'Active' : 'InActive'}`}</span>
            ),
          },
        {
            name: "Action",
            button: true,
            cell: (row) => (
              <button
              className="btn btn-sm btn-view"
              onClick={() => {
                ViewRequest(row._id);
              }}
            >
              View
            </button>
            ),
          },
     ];

     const ViewRequest = (id) =>{
        usersFilter(id)
        navigate(`/user/${id}`)
    }

    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case Boolean("true"):
            result = "completed_icon";
            break;
          case Boolean("false"):
            result = "failed_icon";
            break;
          default:
            break;
        }
        return result;
    };

    

    return (    
        <>
            <AdminSidebar>
                <p className="titlehead">Users</p>
            </AdminSidebar>  
            <div className="usermain">
                 <div className="contain">

                     <div className={`mt-5 ${styles.tradeDiv}`}>
                        <div className={styles.trade_filter_container}>  
                             <div className={styles.transaction_search}>
                                 <div className={`mb-3 mb-md-0 ${styles.search_container}`}>
                                <input
                                    className={styles.search_input_style}
                                    type="text"
                                    placeholder="Search by Name, Email Address"
                                    id="reference"
                                    onChange={handleChange}
                                    value={search}
                                    
                                />
                                 </div>
                             </div>
                         
                          </div>

                        <DataTable
                            title="Users"
                            pagination
                            persistTableHead
                            columns={columns}
                            data={users}
                            progressPending={loader}
                            />
                     </div>

                 </div>
            </div>
        </>
     );
}


const mapStateToProps = (state) =>{
    return{
        users: state.user.users,
        loader: state.user.loader
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsers: (values) => dispatch(getUsers(values)),
        searchUser: (val) => dispatch(searchUsers(val)),
        usersFilter: (id) => dispatch(filterUsers(id)),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersPage);