import { GetApi, PatchApi, PostApi } from "../request";
import cogoToast from "cogo-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

// get withdrawal requests on the platform
export const getAllRequests = () => {
  return async (dispatch, getState) => {
        dispatch({ type: "LoadRequest" });
    try {
      const res = await GetApi("withdrawer/request/all", getToken());
      if (res.status === 200) {
            dispatch({ type: "StopLoadRequest" });
        dispatch({ type: "Requests", data: res.data.message });
      }
      if (res.status === 400) {
         dispatch({ type: "StopLoadRequest" });
        dispatch({ type: "Request_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// filter requests by date,status
export const filterWithdrawalRequests = (val) => {
  var status = val.status;
  var from_date = val.from_date;
  var to_date = val.to_date;
  return async (dispatch, getState) => {
      dispatch({ type: "LoadRequest" });
    try {
      const res = await GetApi(
        `withdrawer/request/all?status=${status}&from_date=${from_date}&to_date=${to_date}`,
        getToken()
      );
      if (res.status === 200) {
         dispatch({ type: "StopLoadRequest" });
        dispatch({ type: "Requests", data: res.data.message });
      }
      if (res.status === 400) {
        dispatch({ type: "StopLoadRequest" });
        dispatch({ type: "Request_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};


// Search request by amount, username or type
export const searchWithdrawalRequests = (val) =>{
  return dispatch =>{
      dispatch({type: 'searchRequests', data: val})
  }
}


// get all withdrawal requests with limits and status
export const getAllRequestWithLimit = () => {
  return async (dispatch, getState) => {
    dispatch({ type: "LoadRequest" });
    try {
      const res = await GetApi("withdrawer/request/all?limit=10&status=Pending", getToken());
      if (res.status === 200) {
        dispatch({ type: "StopLoadRequest" });
        dispatch({ type: "Requests", data: res.data.message });
      }
      if (res.status === 400) {
        dispatch({ type: "StopLoadRequest" });
        dispatch({ type: "Request_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// decline request
  export const declineRequest = (id) => {
    return async (dispatch, getState) => {
      dispatch({ type: "StartLoader" });
      try {
        const res = await PatchApi(
          "withdrawer/request/cancel/" + id, "",
          getToken()
        );
        if (res.status === 200) {
            dispatch({ type: "requestSuccess" });
            cogoToast.success("Request declined successfully!", {
            position: "top-center",
          });
        }
        if (res.status === 400) {
          cogoToast.error("Error while declining request");
          dispatch({ type: "StopLoader" });
        }
      } catch (err) {
        console.log(err);
      }
    };
};
  
// manual approval 
  export const manualRequest = (id) => {
    return async (dispatch, getState) => {
       dispatch({ type: "StartLoader" });
      try {
        const res = await PatchApi(
          "withdrawer/request/manualsuccess/" + id,
          "",
          getToken()
        );
        if (res.status === 200) {
           dispatch({ type: "requestSuccess" });
          cogoToast.success("Request completed successfully!", {
            position: "top-center",
          });
        }
        if (res.status === 400) {
           dispatch({ type: "StopLoader" });
          cogoToast.error("Error while completing request");
        }
      } catch (err) {
        console.log(err);
      }
    };
};

// Flutterwave payment api
 export const flutterWavePayment = (id) => {
   return async (dispatch, getState) => {
     dispatch({ type: "StartLoader" });
     try {
       const res = await PostApi(
         "completerequest/flutter/" + id,
         "",
         getToken()
       );
       if (res.status === 200) {
        dispatch({ type: "requestSuccess" });
         cogoToast.success("Request Completed, Your payment is been processed!", {
           position: "top-center",
         });
       }
       if (res.status === 400) {
         dispatch({ type: "StopLoader" });
         cogoToast.error("Error while making payment");
       }
     } catch (err) {
       console.log(err);
     }
   };
 };
  


  // clear clearRequest success
  export const clearRequestSuccess = () =>{
    return dispatch =>{
        dispatch({type: 'clearRequestSuccess'})
    }
  }