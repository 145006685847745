const initState = {
    transactionAnalytics: [],
    pendingWithdrawerRequest: 0,
    completedTrade: 0,
    totalOutflow: 0,
    allUsersCount: 0
  };
  
  const overviewReducer = (state = initState, action) => {
    switch (action.type) {
        case 'Analytics':
          return{
            ...state,
            totalOutflow: action.data.totalOutflow,
            pendingWithdrawerRequest: action.data.pendingWithdrawerRequest,
            completedTrade: action.data.completedTrade,
            allUsersCount: action.data.allUsersCount,
            transactionAnalytics: action.data.transactionAnalytics
          }
      default:
        return state;
    }
  };
  
  export default overviewReducer;
  




