import React,{useState} from 'react';
import AdminSidebar from '../../../components/AdminSidebar/sidebar';
import styles from './rates.module.css'
import { useFormik } from "formik";
import { AddGiftcardValidator } from "../../../validationSchema/validator";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { UpdateSubCategory } from '../../../store/actions/giftrates';
import cogoToast from 'cogo-toast';


const AdminEditRatePage = ({cards, update}) => {

    const {id} = useParams()

    const card = cards.find(val => val._id === id);

    const categoryId = card.categoryId


    const [value, setValue] = useState(card.termsandconditions  ? card.termsandconditions : '');

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        isSubmitting,
        handleSubmit,
      } = useFormik({
        initialValues: {
            category: card && card.hasOwnProperty("categoryname")
            ? card.categoryname
            : "",
            subcategory: card && card.hasOwnProperty("subcategoryname")
            ? card.subcategoryname
            : "",
            NGNRate: card && card.hasOwnProperty("nairarate")
            ? card.nairarate
            : "",
            minimumUsdValue: card && card.hasOwnProperty("minimumAmount")
            ? card.minimumAmount
            : "",
            maximumUsdValue: card && card.hasOwnProperty("maximumAmount")
            ? card.maximumAmount
            : "",
        },
        validationSchema: AddGiftcardValidator,
        onSubmit(values, { setSubmitting }) {
          handSubmit(values, setSubmitting);
        },
      });
    
      const handSubmit = async (values, setSubmitting) => {
        setSubmitting(true)
        if(value === ''){
            setSubmitting(false)
              cogoToast.info('Terms and Conditions is required')
          }
          else{
  
              let resp = {
                  id: id,
                  categoryId : categoryId,
                  categoryname: values.category,
                  subcategoryname: values.subcategory,
                  termsandconditions: value,
                  nairarate: parseFloat(values.NGNRate),
                  minimumAmount:  parseFloat(values.minimumUsdValue),
                  maximumAmount: parseFloat(values.maximumUsdValue)
              }
              console.log(resp)
              // make api call to add to an Existing category a giftcard
            await update(resp)
      
            //    route
            setTimeout(() => {
                setSubmitting(false)
            }, 1000);
      
          }
      }

    return (
        <>
            <AdminSidebar>
               <p className="titlehead">Giftcard Rates</p>
            </AdminSidebar>
            <div className="usermain">
                 <div className="contain">

                 <div className={`mt-5 ${styles.rates_card}`}>

                        <Link to="/rates" className={styles.goback_div}>
                            <i className='mdi mdi-chevron-left mr-1' style={{fontSize: '20px'}}></i>
                            <p className='mb-0' style={{fontSize: '14px'}}>Go Back</p>
                        </Link>

                        <h5 className={`mt-3 ${styles.title}`}>Edit Giftcard</h5>

                        <form onSubmit={handleSubmit}>

                        <div className="row mt-4">
                        <div className="col-lg-6">
                        {/* form submission */}

                        {/* Category */}
                        <div 
                        className={`mb-3 ${styles.input_container}`}>
                        <label htmlFor="category">Category</label>
                            <input
                            className={`${styles.input_style}`}
                            type="text"
                            placeholder="Amazon"
                            onBlur={handleBlur}
                            onChange={(e) => {
                            handleChange(e);
                            }}
                            id="category"
                            style={{ width: "100%" }}
                            value={values.category}
                            disabled
                        />
                        <small style={{ color: "#dc3545" }}>
                            {touched.category && errors.category}
                        </small>
                        </div>

                        {/* subcategory */}
                        <div 
                        className={`mb-3 ${styles.input_container}`}>
                        <label htmlFor="subcategory">Enter a Subcategory</label>
                        <input
                            className={`${styles.input_style}`}
                            type="text"
                            placeholder="Amazon"
                            onBlur={handleBlur}
                            onChange={(e) => {
                            handleChange(e);
                            }}
                            id="subcategory"
                            style={{ width: "100%" }}
                            value={values.subcategory}
                        />
                        <small style={{ color: "#dc3545" }}>
                            {touched.subcategory && errors.subcategory}
                        </small>
                        </div>                 

                        <div className="row mt-2">
                        <div className="col-lg-6">
                            {/* ghs rate */}
                            <div 
                            className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="minimumUsdValue">Minimum USD Amount</label>
                            <input
                                className={`${styles.input_style}`}
                                type="text"
                                placeholder="0"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                id="minimumUsdValue"
                                style={{ width: "100%" }}
                                value={values.minimumUsdValue}
                            />
                            <small style={{ color: "#dc3545" }}>
                                {touched.minimumUsdValue && errors.minimumUsdValue}
                            </small>
                            </div>

                        </div>

                        <div className="col-lg-6">
                                {/* Ngn rate */}
                            <div 
                            className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="maximumUsdValue">Maximum USD Amount</label>
                            <input
                            className={`${styles.input_style}`}
                                type="text"
                                placeholder="0"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                id="maximumUsdValue"
                                style={{ width: "100%" }}
                                value={values.maximumUsdValue}
                            />
                            <small style={{ color: "#dc3545" }}>
                                {touched.maximumUsdValue && errors.maximumUsdValue}
                            </small>
                        </div>

                        </div>

                        </div>

                        <div className="row">
                        <div className="col-lg-6">
                                {/* Ngn rate */}
                            <div
                            className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="NGNRate">NGN Rate</label>
                            <input
                                className={`${styles.input_style}`}
                                type="text"
                                placeholder="0"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                id="NGNRate"
                                style={{ width: "100%" }}
                                value={values.NGNRate}
                            />
                            <small style={{ color: "#dc3545" }}>
                                {touched.NGNRate && errors.NGNRate}
                            </small>
                        </div>

                        </div>

                        </div>   


                        </div>

                        <div className="col-lg-6">

                            <div
                            className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="terms">Terms and Conditions</label>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={value}
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setValue(data)
                                    
                                } }
                                
                            /> 
                                <small style={{ color: "#dc3545" }}>
                                    {value === '' ? 'Terms and Conditions is required' : ''}
                                </small> 
                        </div>


                        </div>

                        </div>

                        <div className='text-center'>
                            <button
                            type="submit"
                            className={`btn mt-3 mt-lg-4 ${styles.btn_add}`}
                            disabled={isSubmitting}
                            >
                            Update Giftcard
                            </button>
                          </div>
                    </form>

                  </div>
                </div>
             </div>
        </>
      );
}

const mapStateToProps = (state) => {
    return {
     cards: state.giftrate.subcategory,
    }
   }
   
   const mapDispatchToProps = (dispatch) => {
    return {
      update: (creds) => dispatch(UpdateSubCategory(creds)),
    };
   }
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminEditRatePage);