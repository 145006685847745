import React,{useState, useEffect} from 'react'
import AdminSidebar from '../../../components/AdminSidebar/sidebar';
import styles from './trades.module.css'
import DataTable from "react-data-table-component";
import hamburger from "../../../assets/icons/align-left.svg";
import { useNavigate } from 'react-router-dom';
import Modal from "../../../components/Modals/FilterModal";
import { filterValidator } from "../../../validationSchema/validator";
import { Form, Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import { getFilteredGiftCardsTrade, getGiftCardsTrade, searchTrade } from '../../../store/actions/trades';
import Moment from "react-moment";


const AdminTradesPage = ({fetchTrades, trades, loader,searchTrade, fetchFilteredTrades}) => {

    useEffect(()=>{
        let status = 'All'
        fetchTrades(status)
      },[fetchTrades])

      const [search, setSearchValue] = useState("");

      const handleChange = (e) => {
          setSearchValue(e.target.value);
       };
    
    useEffect(() => {
        searchTrade(search)
     }, [search, searchTrade]);


    const navigate = useNavigate();

    const [showFilterModal, setFilterModal] = useState(false);
    
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const columns = [
        {
            name: 'Card Name',
            cell: (row) => (
                <span className={styles.recent_table_row}>{
                  `${row.hasOwnProperty('subCategoryDetails') ? row.subCategoryDetails.categoryname : 'Giftcard'}`
                  }</span>
            ),
        },
        {
            name: 'Card Amount',
            cell: (row) => (
                <span className={styles.recent_table_row}>{`USD ${row.cardAmount}`}</span>
              ),
        },
        {
            name: 'Date Intialized',
            cell: (row) => (
                <span className={styles.recent_table_row}>
                 <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
                </span>
            ),
        },
        {
            name: 'Trade Status',
            cell: (row) => (
                <span className={getStatusColor(row.tradeStatus)}>{`${row.tradeStatus}`}</span>
            ),
        },
        {
            name: "Action",
            button: true,
            cell: (row) => (
              <button
              className="btn btn-sm btn-view"
              onClick={() => {
                ViewRequest(row._id);
              }}
            >
              View
            </button>
            ),
          },
     ];

     const ViewRequest = (id) =>{
        navigate(`/trade/details/${id}`)
    }

    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result = "pending_icon";
            break;
          case "Completed":
            result = "completed_icon";
            break;
          case "Declined":
            result = "failed_icon";
            break;
          default:
            result = "failed_icon";
            break;
        }
        return result;
      };

   
    const formatDate = (date) => {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
    
        return [year, month, day].join("-");
    };

    const handleSubmit = async (values, setSubmitting) => {
        let res;
        res = {
          from_date: formatDate(startDate),
          to_date: formatDate(endDate),
          status: values.status,
        };
        console.log(res)
        setFilterModal(false);
        await fetchFilteredTrades(values.status, formatDate(startDate), formatDate(endDate))
    }


    return (    
        <>
            <AdminSidebar>
                <p className="titlehead">Trades</p>
            </AdminSidebar>  
            <div className="usermain">
                 <div className="contain">

                 <Modal
                    title="Filter"
                    show={showFilterModal}
                    onClose={() => setFilterModal(false)}
                    >
                    
                    <div>
                        <Formik
                            onSubmit={(values, { setSubmitting }) =>
                            handleSubmit(values, setSubmitting)
                            }
                            validationSchema={filterValidator}
                            initialValues={{
                            status: "",
                            }}
                        >
                            {({
                            handleChange,
                            isSubmitting,
                            handleSubmit,
                            handleBlur,
                            setFieldValue,
                            values,
                            touched,
                            errors,
                            }) => (
                            <Form onSubmit={handleSubmit}>
                                {/* Date*/}
                                <div className="row mt-4">
                                <div className="col-lg-6">
                                    <div 
                                    className={`mb-3 ${styles.input_container}`}>
                                    <label htmlFor="startDate">From Date</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        className={`${styles.filter_input_style}`}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div
                                    className={`mb-3 ${styles.input_container}`}>
                                    <label htmlFor="startDate">To Date</label>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        className={`${styles.filter_input_style}`}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    </div>
                                </div>
                                </div>

                                {/* status */}
                                <div 
                                className={`mb-3 ${styles.input_container}`}>
                                <label htmlFor="status">Status</label>
                                <select
                                    name="status"
                                    values={values.status}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    defaultValue=""
                                    className={`${styles.filter_input_style}`}
                                    id="status"
                                >
                                    <option value="" disabled>--Select--</option>
                                    <option value="All">All</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Completed">Completed</option>
                                    <option value="Declined">Declined</option>
                                </select>
                                <small style={{ color: "#dc3545" }}>
                                    {touched.status && errors.status}
                                </small>
                                </div>

                                <div className="text-center mt-4 ">
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={`btn ${styles.btn_filter}`}
                                >
                                    Apply Filter
                                </button>
                                </div>
                            </Form>
                            )}
                        </Formik>
                        </div>
                  </Modal>


                     <div className={`mt-5 ${styles.tradeDiv}`}>
                        <div className={styles.trade_filter_container}>  
                             <div className={styles.transaction_search}>
                                 <div className={`mb-3 mb-md-0 ${styles.search_container}`}>
                                <input
                                    className={styles.search_input_style}
                                    type="text"
                                    placeholder="Search by Status, Card Name"
                                    id="reference"
                                    onChange={handleChange}
                                    value={search}
                                    
                                />
                                 </div>
                             </div>
                          <div className='ml-lg-4 ml-0'>
                            <button
                                className={`btn ${styles.filter_div}`}
                                onClick={() => setFilterModal(true)}
                                >
                                <img
                                    src={hamburger}
                                    width="20"
                                    height="20"
                                    alt="hamburger"
                                    className="mr-2"
                                />
                                Filter By
                                </button>
                                </div>
                          </div>

                        <DataTable
                            title="Trades"
                            pagination
                            persistTableHead
                            columns={columns}
                            data={trades}
                            progressPending={loader}
                        />
                     </div>

                 </div>
            </div>
        </>
     );
}


const mapStateToProps = (state) => {
    return {
      trades: state.trade.trades,
      loader: state.trade.loader
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      fetchTrades: (status) => dispatch(getGiftCardsTrade(status)),
      fetchFilteredTrades: (status, from, end) => dispatch(getFilteredGiftCardsTrade(status, from, end)),
      searchTrade: (val) => dispatch(searchTrade(val)),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(AdminTradesPage);